<template>
  <article class="traffic-permit-container">
    <div class="frame">
      <header class="traffic-permit-header">
        <div class="logo-id">
          <div class="logo-group">
            <div class="logo-t">
              <img src="../img/JCDClogo.png" alt="" />
            </div>
            <div class="logo-s">
              <img src="../img/CHEClogo.png" alt="" />
            </div>
          </div>
          <div class="ID">
            ID: {{ data.code ? data.code : "-" }}
          </div>
        </div>
        <div class="title-logo">
          <div class="title">{{data.is_driver === 1 ?'Temporary Vehicle Pass':'Temporary Personnel Pass'}}</div>
          <div class="logo">
            <img src="../img/titleDecoration.png" alt="" />
          </div>
        </div>
      </header>
      <div class="message-container text-style">
        <div class="validity-lssuing">
          <div class="validity-period">
            Validity Period:&nbsp;&nbsp;{{ validityPeriod }}
          </div>
          <div class="desc">
            Issuing Unit:&nbsp;&nbsp;{{
              data.company_name
                ? data.company_name
                : "-"
            }}
          </div>
        </div>
        <ul class="message">
          <li
            class="message-column"
            v-for="(item, index) in messageData"
            :key="index"
            :class="data.is_driver === 0 ?'person-message':''"
          >
            <span class="item-key">{{ item.key }}&nbsp;&nbsp;</span>
            <span class="item-value" :class="['Num of Companions:', 'Gate Name:'].includes(item.key)?'go-beyond':'go-beyond-two'">{{ item.value?item.value:'-' }}</span>
          </li>
        </ul>
        <div class="visitor-notes">
          <img src="../img/leftLine.png" alt="">
          <span class="desc">Visitor Notes</span>
          <img src="../img/rightLine.png" alt="">
        </div>
        <div class="visitor-notes-container">
          <ul class="visitor-notes-list">
            <li class="visitor-notes-item" v-for="(item, index) in visitorNotesData" :key="index">
              {{ `${index + 1}. ${item}` }}
            </li>
          </ul>
          <div class="photo-ID">
            <img v-if="data.qrcode" :src="data.qrcode" alt="" />
          </div>
        </div>
      </div>
    </div>
  </article>
</template>
<script>
import { dataInterface } from '@/apis/data';

export default {
  name: 'index',
  components: {},
  props: [],
  data () {
    return {
      messageData: [],
      userData: [
        // {
        //   key: "Visitor's Company:",
        //   value: ''
        // },
        {
          key: "Visitor's Full Name:",
          value: ''
        },
        // {
        //   key: "Visitor's Job Title:",
        //   value: ''
        // },
        {
          key: 'Gate Name:',
          value: ''
        },
        {
          key: 'Identification Number:',
          value: ''
        }
      ],
      carData: [
      // {
      //     key: "Visitor's Company:",
      //     value: ''
      //   },
        {
          key: 'Plate Number:',
          value: ''
        },
        {
          key: "Visitor's Full Name:",
          value: ''
        },
        {
          key: 'Manufacturer:',
          value: ''
        },
        {
          key: 'Identification Number:',
          value: ''
        },
        {
          key: 'Vehicle Type:',
          value: ''
        },
        // {
        //   key: "Visitor's Job Title:",
        //   value: ''
        // },
        {
          key: "Num of Companions:",
          value: ''
        },
        {
          key: "Gate Name:",
          value: ''
        }
      ],
      visitorNotesData: [],
      data: {}
    }
  },
  computed: {
    validityPeriod() {
      const startDate = this.data.start_date?.split('-')?.reverse()?.join('/');
      const endDate = this.data.end_date?.split('-')?.reverse()?.join('/');
      return `${startDate} - ${endDate}` || '-';
    }
  },
  created () {
    this.getData();
    this.getVisitorNotes();
  },
  methods: {
    /**
     * @desc: 获取列表
     */
    getData () {
      const dataId = window.location.href.split('?data_id=')?.[1];
      dataInterface({
        object_uuid: 'object660ba01b1525a',
        view_uuid: 'view661f456eebb62',
        __method_name__: 'dataInfo',
        data_id: dataId,
        transcode: 0
      }).then((res) => {
        if (res.data.code !== 200) return;
        this.data = res.data.data;
        if (this.data.is_driver === 0) {
          // 生成人员通行证
          this.userData.forEach(item => {
            item.value = this.data[this.getKey(item.key)] ? this.data[this.getKey(item.key)] : '-';
          })
          this.messageData = this.userData;
        }
        if (this.data.is_driver === 1) {
          // 生成车辆通行证
          this.carData.forEach(item => {
            item.value = this.data[this.getKey(item.key)] ? this.data[this.getKey(item.key)] : '-';
            if (item.key === 'Plate Number:') {
              if (item.value && item.value.includes(',')) {
                item.value = item.value.split(',').join('');
              }
            }
          })
          this.messageData = this.carData;
        }
        this.messageData.forEach(item => {
          const pattern = /(\d+)#/;
          if (item.key === 'Gate Name:') {
            if (item.value && pattern.test(item.value)) {
              item.value = this.extractNumberHash(item.value);
            }
          }
        })
      })
    },
    extractNumberHash(str) {
      const matches = str.match(/(\d+)#/g);
      return matches ? matches.join(',') : null;
    },
    getVisitorNotes() {
      dataInterface({},'api/graph/920').then(res=>{
        if(res.data.code === 200){
          this.visitorNotesData = res.data.data;
        }
      })
    },
    getKey (key) {
      switch (key) {
        case "Visitor's Company:":
          return 'Visiting_Company';
        case "Visitor's Full Name:":
          return "full_name";
        case 'Identification Number:':
          return 'passport';
        case 'Company Name:':
          return 'Visiting_Company';
        case "Visitor's Job Title:":
          return "Visiting_Job";
        case 'Gate Name:':
          return 'channel';
        case 'Plate Number:':
          return 'vehicle_number_show';
        case 'Manufacturer:':
          return 'brand_name';
        case 'Vehicle Type:':
          return 'type_name';
        case 'Num of Companions:':
          return 'Visitor_Number';
        case 'License Effective Date:':
          return 'start_date';
        default: '-';
      }
    }
  }
}
</script>
<style lang="less" scoped>
.traffic-permit-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../img/yellowBackground.png");
  background-position: 100% 100%;
  background-size: 100% 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: revert-layer;
  }
  .frame {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .traffic-permit-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 364px 420px 0px 413px;
      box-sizing: border-box;
      .logo-id {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 150px;
        .logo-group {
          display: inline-flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: 16px;
          width: 508px;
          height: 100%;
          border-radius: 12px;
          .logo-t {
            width: 280px;
            height: 100%;
          }
          .logo-s {
            width: 212px;
            height: 100%;
          }
        }
        .ID {
          flex: 1;
          color: #A47C43;
          font-family: "Microsoft YaHei";
          font-size: 60px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-align: right;
          white-space: nowrap;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
        }
      }
      .title-logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .title {
          display: flex;
          justify-content: center;
          width: 100%;
          margin-top: 78px;
          color: #A47C43;
          font-family: "Microsoft YaHei";
          font-size: 125px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          white-space: nowrap;
        }
        .logo {
          width: 1396px;
          height: 162px;
          margin: 31px 0 112px 0;
        }
      }
    }
    .message-container {
      width: 100%;
      padding: 0 320px 0 360px;
      box-sizing: border-box;
      .validity-lssuing {
        text-align: center;
        .validity-period {
          padding-bottom: 24px;
          box-sizing: border-box;
        }
        .desc {
          width: 100%;
          height: 132px !important;
          word-break: keep-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .message {
        display: grid;
        grid-template-columns: repeat(2, 880px);
        row-gap: 32px;
        column-gap: 40px;
        width: 100%;
        margin-top: 66px;
        .message-column {
          display: flex;
          width: 100%;
          height: 132px;
          overflow: hidden;
          &:last-child {
            height: 66px;
          }
          &:nth-last-child(2) {
            height: 66px;
          }
          .item-key {
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
          }
          .go-beyond-two {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
          }
          .go-beyond {
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .item-value {
            flex: 1;
            height: 100%;
            overflow: hidden;
            word-break: keep-all;
          }
        }
        .person-message {
          &:first-child {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-column: 1/5;
          }
        }
      }
      .visitor-notes {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 52px;
        height: 160px;
        margin: 60px 0 60px 0;
        img {
          flex: 1;
          height: 7px;
          object-fit: revert-layer;
        }
        .desc {
          color: #A47C43;
          font-family: "Microsoft YaHei";
          font-size: 50px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
      .visitor-notes-container {
        display: flex;
        align-items: center;
        gap: 180px;
        .visitor-notes-list {
          display: flex;
          flex-direction: column;
          gap: 48px;
          width: 1097px;
          height: 738px;
          .visitor-notes-item {
            color: #A47C43;
            font-family: "Microsoft YaHei";
            font-size: 50px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: left;
          }
        }
        .photo-ID {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 480px;
          height: 480px;
          background-image: url("../img/QRCode.png");
          background-position: 100% 100%;
          background-size: 100% 100%;
          z-index: 10;
          img {
            width: 420px;
            height: 420px;
            border-radius: 8px;
            object-fit: revert-layer;
          }
        }
      }
    }
    .text-style {
      height: 66px;
      color: #A47C43;
      text-align: left;
      font-family: "Microsoft YaHei";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.1px;
    }
  }
}
</style>
